<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Uomsform from "./components/Uomsform.vue";

import { fetchProductsUomsApi } from "@/api/common";
import Swal from "sweetalert2";
export default {
  import: {fetchProductsUomsApi},
  page: {
    title: "Unités de mesure",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Uomsform },
  data() {
    return {
      selectedUoms: null,
      currentSelectedUoms:[],
      tableData: [],
      List_Uoms: [],
      rows:'',
      active:false,
      selectedRows:[],
      unite:undefined,
      title: "Unités de mesure",
      items: [
        {
          text: "Paramètres",
          href: "javascript:void(0)",
        },
        {
          text: "Unités de mesure",
          active: true,
        },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      refresh:false
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;
    // fetch divisions from api
    this.fetchUomsList();
  },
   watch:{
      selectedRows(){
        console.log(this.selectedRows);
      },
      unite:{
          handler(val){
            this.List_Uoms.push({
                name:val.name,
                description:val.description  
            });
            console.log(this.List_Uoms);
          } 
      },
      refresh:{
        handler(val){
          if(val) this.fetchUomsList();
        }
      }
  },
  methods:{
    fetchUomsList(){
      fetchProductsUomsApi()
      .then(res => (this.List_Uoms = res.data.original.data))
      // .then(res => (console.log(res)))
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },
    refreshUoms(){
      this.getUoms();
    },
     deleteUoms(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer l'unité :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/system/stock/uom/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  _this.fetchUomsList();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    handleLoadUomsDataToFormComponent(selectedUoms){
      if(selectedUoms){
        this.editUoms(selectedUoms.uuid);
        
      }
    },  

    updateTabsUi(){
      this.generalInfosSetUp = Boolean(this.currentSelectedUoms.general_infos_setup);
      this.scopeSetUp = Boolean(this.currentSelectedUoms.scope_setup);
      this.financeInfosSetUp = Boolean(this.currentSelectedUoms.financial_infos_setup);
      this.priceListInfosSetUp = Boolean(this.currentSelectedUoms.pricelist_infos_setup);
      this.synopticsInfosSetUp = Boolean(this.currentSelectedUoms.synptics_infos_setup);
    },

    handleGeneralInfoStepSavedEvent(unite){

      this.currentSelectedUoms = unite;

      this.generalInfosSetUp = true;
      // refresh contracts list
      this.shouldRefreshUomsTable = true;
      // jump to next tab
      this.formStep = 1;
    },

  }
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des  Unités de mesure  </div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <!-- <table class="table table-centered table-nowrap"> -->
                  <v-table
                    class="table table-hover"
                    :data="List_Uoms"
                    selectionMode="single"
                    selectedClass="table-info"
                    @selectionChanged="currentSelectedUoms = $event "
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Désignation</th>
                      <!-- <th>Description</th> -->
                      <th>Actions</th>

                    </tr>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}">
                    <v-tr v-for="row in displayData" :key="row.id" :row="row">
                      <td>
                        {{ row.designation }}
                      </td>
                      <!-- <td>{{ data.description }}</td> -->
                      <td>

                        <a
                          v-if="row.systeme == 0"
                          @click="deleteUoms(row.designation, row.uuid)"
                          class="text-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Supprimer"
                          data-original-title="Delete"
                          ><i class="mdi mdi-close font-size-18"></i
                        ></a>
                      </td> 
                    </v-tr>
                  </tbody>
                </v-table>
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="mt-3">
            <Uomsform :unite="currentSelectedUoms[0]" @refresh ="refresh = $event" />
        </div>
      </div>
    </div>
  </Layout>
</template>