<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
 import { required ,maxLength} from "vuelidate/lib/validators";
// import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
// import Infrastructure from './infrastructure.vue';
// import Multiselect from 'vue-multiselect'
  import Swal from "sweetalert2";

export default {

    page: {
        title: "Unité de Mesure",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: { },
    props:{
     unite: {
      type: Object,
      default: null,
    }
    },
  data() {
    return {
      title: "Nouvelle Magasin",
      uniteForm: {
        designation: "",
        type:"uom"
      },
       
      userStructuresTypes: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      
    };
  },
  validations: {
    uniteForm: {
      designation: { required, maxLength: maxLength(64) },
    },
  },

  watch:{
    unite(){
      console.log(this.unite);
      if(this.unite){
        this.uniteForm.uuid = this.unite.uuid;
        this.uniteForm.designation = this.unite.designation;
        // this.uniteForm.type = this.unite.type;
   
      }else{
        this.clearForm();
      }
    }  
  },
  mounted(){

},

 methods:{
  
     formSubmit() {
       
      this.submitted = true;
      this.$v.$touch();
      var valid = this.$v.uniteForm.$invalid;

      if(valid){
      this.$http
        .post("/system/stock/uom/store", this.uniteForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              Swal.fire("Félicitations!", res.data.original.msg, "success");
              this.$v.$reset();
              this.$emit('refresh',true);
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
     updateUOM() {
       
      this.submitted = true;
      this.$v.$touch();
      var valid = this.$v.uniteForm.$invalid;

      if(valid){
      this.$http
        .post("/system/stock/uom/update", this.uniteForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              Swal.fire("Félicitations!", res.data.original.msg, "success");
              this.$v.$reset();
              this.$emit('refresh',true);
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
  }
    
}
</script>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Désignation *</label>
                    <input
                      id="projectname"
                      v-model="uniteForm.designation"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.uniteForm.designation.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.uniteForm.designation.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.uniteForm.designation.required"
                        >Le champ designation est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <!-- <div class="col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Description</label>
                    <input
                      id="projectname"
                      v-model="uniteForm.description"
                      type="text"
                      class="form-control"
                      
                      placeholder=""
                    />
                  </div>
                </div> -->
              </div>

              <div>
                <div class="row">
                  <div v-if="uniteForm.uuid" class="col text-right">
                    <button
                      class="btn btn-warning"
                      :disabled="$v.uniteForm.$invalid"
                      type="button"
                      @click="updateUOM"
                    >
                          Modifier
                    </button>
                  </div>
                  <div v-else class="col text-right">
                    <button
                      class="btn btn-primary"
                      :disabled="$v.uniteForm.$invalid"
                      type="submit"
                    >
                          Enregistrer
                    </button>
                  </div>
                </div>
                
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
